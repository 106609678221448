<template>
  <v-expansion-panels focusable style="margin-bottom: 25px;">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:actions>
          <v-icon class="icon">$expand</v-icon>
        </template>

        <div @click.stop class="title-header">
          <input type="text" v-model="edit.carrossel.titulo" />
        </div>

        <div class="crud-header" @click.stop>
          <div class="description">
            {{ resumoLocais }}

            <br />

            {{ formatDate(edit.inicio_vigencia) }} à
            {{ formatDate(edit.fim_vigencia) }}
          </div>

          <div class="switchCarrossel">
            <v-switch
              color="primario"
              inset
              :label="edit.carrossel.ativo ? 'Ativo' : 'Inativo'"
              v-model="edit.carrossel.ativo"
              @change="toggleSlider"
            >
            </v-switch>
          </div>

          <div class="container-icon">
            <i class="fas fa-trash iconTrash" @click="deleteCarrossel"></i>
          </div>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <CarrosselConteudoTemplate
          :imageEditorMode="imageEditorMode"
          :productEditorMode="productEditorMode"
          :isCarrosselProdutosCliente="isCarrosselProdutosCliente"
          :showComponente="showComponente"
          :isCarrosselListaCompras="isCarrosselListaCompras"
          :isCarrosselProdutos="isCarrosselProdutos"
          :slider="slider"
          :paginasNavegacao="paginasNavegacao"
          :paginasEstaticas="paginasEstaticas"
          :setoresFolha="setoresFolha"
          :edit="edit"
          :locaisExibicao="locaisExibicao"
          :segmentosCliente="segmentosCliente"
          :computeResumoLocais="computeResumoLocais"
          :refresh="refreshSlides"
          :refreshSlideAction="refreshSlideAction"
          :deleteSlide="deleteSlide"
          @data-reload="dataChild"
          ref="carrosselConteudoTemplate"
        />

        <div class="panel-footer">
          <FormButton
            text="Pré-visualização"
            type="auxiliar"
            class="botao"
            :disabled="!slider.slides.length"
            @click="$refs.carrosselConteudoTemplate.openPreview()"
            v-if="imageEditorMode"
          />

          <FormButton
            text="Salvar"
            type="primario"
            class="botao"
            @click="save"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CarrosselConteudoTemplate from './CarrosselConteudoTemplate'
import FormButton from '@/components/form/FormButton'
import {
  carrossel_types,
  carrossel_types_list
} from '../../utils/utilCarrossel'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { CARROSSEL_UPDATE } from '@/store/actions/carrossel'
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as _ from 'lodash'

export default {
  components: {
    CarrosselConteudoTemplate,
    FormButton
  },
  emits: ['delete', 'refresh'],
  props: {
    slider: Object,
    productEditorMode: Boolean,
    setoresFolha: Array,
    segmentosCliente: Array,
    produtos: Array,
    paginasEstaticas: Array,
    paginasNavegacao: Array,
    imageEditorMode: Boolean,
    index: Number
  },
  data() {
    return {
      defaultSpeed: 0,
      carouselTypes: carrossel_types,
      carrosselTypeList: carrossel_types_list,
      resumoLocais: '',
      toggleTimeout: null,
      showEditorAdvancedPanel: false,
      disableToggle: false,
      navStyles: [
        { name: 'angleDouble', style: 'fas fa-angle-double-right' },
        { name: 'chevron', style: 'fas fa-chevron-right' },
        { name: 'carret', style: 'fas fa-caret-right' },
        { name: 'chevronCircle', style: 'fas fa-chevron-circle-right' },
        { name: 'arrowCircle', style: 'fas fa-arrow-circle-right' }
      ],
      datePickerConfig: {
        dayViewHeaderFormat: 'MMMM YYYY',
        format: 'DD/MM/YYYY',
        showTodayButton: true,
        showClear: true,
        showClose: true,
        useCurrent: false,
        locale: moment.locale('pt-br')
      },
      edit: {
        actionType: 'nothing',
        param: null,
        image: '',
        produto: {},
        combo: {
          comboEspecifico: false
        },
        paginaEstatica: {},
        paginaNavegacao: {},
        tipoConteudo: 'STATIC_IMAGES',
        categoriaSelecionada: {},
        tipoCarrosselSelecionado: {},
        segmentoClienteSelecionado: {},
        inicio_vigencia: null,
        fim_vigencia: null,
        autoplaySpeed: this.defaultSpeed,
        fade: false,
        ordem: 'lista-produtos:1',
        selectedNavStyle: {
          name: 'angleDouble',
          style: 'fas fa-angle-double-right'
        },
        selectedLocalExibicao: null,
        centerMode: false,
        showTitle: false,
        slidesToShowResponsive: {
          desktop: 3,
          mobile: 1,
          fidelidade: 1
        },
        selectedDevice: {
          mobile: true,
          desktop: false,
          fidelidade: false
        },
        loginRequired: false,
        navStyle: {
          arrowRightInverted: false,
          chevronInverted: false,
          caretRightInverted: false,
          chevronRight: false,
          doubleCarretRight: false
        },
        carrossel: {
          idcarrossel: null,
          titulo: null,
          slides: [],
          config: {
            speed: this.defaultSpeed,
            showDots: true,
            fixedTop: false,
            carouselType: carrossel_types.CARROSSEL_PRODUTOS,
            colors: {
              font: '#000000FF',
              background: ''
            },
            showOnPesquisaAtivada: false,
            bannerPrincipal: false
          },
          idsegmentocliente: null,
          device: [],
          inicio_vigencia: null,
          fim_vigencia: null,
          ativo: false,
          idfornecedor: null,
          localexibicao: [],
          area_pagina: 'lista-produtos',
          ordem_area: 1
        }
      },
      colorPickerActive: '',
      usesAppFidelizacao: false
    }
  },
  computed: {
    ...mapGetters(['moduloFornecedorAtivo']),

    isCarrosselProdutos() {
      return (
        this.edit.tipoCarrosselSelecionado.value ||
        this.edit.tipoCarrosselSelecionado ===
          this.carouselTypes.CARROSSEL_PRODUTOS
      )
    },
    isCarrosselProdutosCliente() {
      return (
        this.edit.tipoCarrosselSelecionado.value ||
        this.edit.tipoCarrosselSelecionado ===
          this.carouselTypes.CARROSSEL_PRODUTOS_CLIENTE
      )
    },
    isCarrosselListaCompras() {
      return (
        this.edit.tipoCarrosselSelecionado.value ||
        this.edit.tipoCarrosselSelecionado ===
          this.carouselTypes.CARROSSEL_LISTA_COMPRAS
      )
    },
    categorias() {
      const folhas = (this.setoresFolha || []).map(function(folha) {
        return {
          text: folha.rotulo,
          value: `categoria:${folha.id}|${folha.rotulo}`
        }
      })

      return folhas
    }
  },
  created() {
    this.applyCarrossel(this.slider)
    this.disableToggle = this.getDisableToogle()

    if (this.imageEditorMode) {
      this.edit.tipoConteudo = 'STATIC_IMAGE'
    } else if (this.productEditorMode) {
      this.edit.tipoConteudo = 'PRODUCT_CATEGORY'
    }
  },
  methods: {
    applyDevices() {
      if (!this.edit.carrossel.device) {
        this.edit.carrossel.device = ['mobile', 'desktop']
      }
      this.edit.selectedDevice.mobile =
        this.edit.carrossel.device.indexOf('mobile') >= 0
      this.edit.selectedDevice.desktop =
        this.edit.carrossel.device.indexOf('desktop') >= 0
      this.edit.selectedDevice.fidelidade =
        this.edit.carrossel.device.indexOf('fidelidade') >= 0
    },
    applyAutoplaySpeed() {
      this.edit.carrossel.config.autoplaySpeed = this.edit.autoplaySpeed
    },
    applyAutoplay() {
      this.edit.carrossel.config.autoplay = this.edit.speed > 0
    },
    applyCenterMode() {
      this.edit.carrossel.config.centerMode = this.edit.centerMode
      this.edit.carrossel.config.centerPadding = this.edit.centerMode
        ? '60px'
        : null
    },
    applyFade() {
      this.edit.carrossel.config.fade = this.edit.fade
    },
    applyNavStyle() {
      if (!this.edit.selectedNavStyle) {
        this.edit.selectedNavStyle = this.navStyles[0]
      }
      this.edit.carrossel.config.navStyle =
        this.edit.selectedNavStyle.style || this.navStyles[0].style
    },
    computeResumoLocais() {
      const locais = (this.edit.selectedLocalExibicao || []).map(l => {
        return Object.keys(l).includes('text')
          ? l.text
          : this.locaisExibicao().filter(local => local.value === l)[0].text
      })

      if (!locais.length) {
        return (this.resumoLocais = 'Nenhuma página')
      }

      const resumoLocais = Array.from(locais).splice(0, 3)

      let textoResumido = resumoLocais.reduce(function(l1, l2) {
        return l1 + ' - ' + l2
      })

      if (resumoLocais.length == 0) {
        textoResumido = 'Nenhuma página'
      }

      this.resumoLocais =
        textoResumido +
        (locais.length > resumoLocais.length
          ? ' e +' + (locais.length - resumoLocais.length) + ' outros'
          : '')
    },
    applySlidesToShowResponsive: function() {
      this.edit.carrossel.config.slidesToShowResponsive = {
        desktop: 3,
        mobile: 1,
        fidelidade: 1
      }
      this.edit.carrossel.config.slidesToShowResponsive.desktop = this.edit.slidesToShowResponsive.desktop
      this.edit.carrossel.config.slidesToShowResponsive.mobile = this.edit.slidesToShowResponsive.mobile
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    },
    locaisExibicao() {
      let folhas = []

      if (
        this.showComponente([
          ModulesEnum.COMMERCE_APP,
          ModulesEnum.WEB_COMMERCE,
          ModulesEnum.ORCAMENTO_WEB,
          ModulesEnum.ORCAMENTO_APP
        ])
      ) {
        folhas = this.categorias
      }

      return [
        { text: 'Lista inicial de produtos', value: 'page:home' },
        { text: 'Detalhes de produto', value: 'page:product-details' },
        { text: 'Todas as páginas', value: 'page:all' }
      ].concat(folhas)
    },
    tiposCarrosseis() {
      if (
        this.showComponente([
          ModulesEnum.COMMERCE_APP,
          ModulesEnum.WEB_COMMERCE
        ])
      ) {
        return this.carrosselTypeList.type1
      } else {
        return this.carrosselTypeList.type2
      }
    },
    getDisableToogle() {
      if (this.imageEditorMode) {
        return this.edit.carrossel.slides.length == 0
      } else if (
        this.productEditorMode &&
        this.isCarrosselProdutos &&
        !this.isCarrosselProdutosCliente
      ) {
        if (
          this.showComponente([
            ModulesEnum.COMMERCE_APP,
            ModulesEnum.WEB_COMMERCE,
            ModulesEnum.ORCAMENTO_WEB,
            ModulesEnum.ORCAMENTO_APP
          ])
        ) {
          return !this.edit.carrossel.idsetor
        } else {
          return false
        }
      } else {
        return false
      }
    },
    applyCarrossel(novoCarrossel) {
      const config = novoCarrossel.config
        ? { ...this.edit.carrossel.config, ...novoCarrossel.config }
        : this.edit.carrossel.config
      this.edit.carrossel = { ...novoCarrossel, config }
      this.applyDevices()
      this.edit.autoplaySpeed =
        novoCarrossel.config.autoplaySpeed || this.defaultSpeed
      this.applyAutoplaySpeed()
      this.applyAutoplay()
      this.edit.fade =
        novoCarrossel.config.fade != undefined
          ? novoCarrossel.config.fade
          : false
      this.applyFade()
      this.edit.selectedNavStyle =
        this.navStyles.find(function(s) {
          return s.style == novoCarrossel.config.navStyle
        }) || this.navStyles[0]
      this.applyNavStyle()

      this.edit.inicio_vigencia = novoCarrossel.inicio_vigencia
      this.edit.fim_vigencia = novoCarrossel.fim_vigencia

      const locais = this.slider.localexibicao
      this.edit.selectedLocalExibicao = this.locaisExibicao().filter(local => {
        return locais.includes(local.value.split('|')[0])
      })

      this.computeResumoLocais()
      this.edit.ordem =
        novoCarrossel.area_pagina + ':' + novoCarrossel.ordem_area
      this.edit.slidesToShowResponsive = novoCarrossel.config
        .slidesToShowResponsive || {
        desktop: 3,
        mobile: 1,
        fidelidade: 1
      }
      this.edit.centerMode = novoCarrossel.config.centerMode || false
      this.edit.showTitle = novoCarrossel.config.showTitle || false
      this.applySlidesToShowResponsive()
      this.edit.loginRequired =
        this.edit.carrossel.config.loginRequired || false
      this.edit.categoriaSelecionada = novoCarrossel.idsetor

      const carrosseis = _.get(
        this.tiposCarrosseis().find(
          t => t.value === this.edit.carrossel.config.carouselType
        ),
        'value',
        ''
      )
      this.edit.tipoCarrosselSelecionado = carrosseis

      this.edit.segmentoClienteSelecionado =
        (this.segmentosCliente || []).find(
          s => s.value === this.edit.carrossel.idsegmentocliente
        ) || null
    },
    deleteCarrossel() {
      this.$emit('delete', this.slider.idcarrossel)
    },
    toggleSlider() {
      const cantSave = !this.edit.categoriaSelecionada && this.productEditorMode

      if (!this.edit.carrossel.slides.length && this.imageEditorMode) {
        setTimeout(() => {
          this.edit.carrossel.ativo = false
        }, 200)

        return this.$vueOnToast.pop(
          'warning',
          'Adicione ao menos uma imagem ao carrossel'
        )
      } else if (
        cantSave &&
        this.isCarrosselProdutos &&
        !this.isCarrosselProdutosCliente &&
        this.showComponente([
          ModulesEnum.COMMERCE_APP,
          ModulesEnum.WEB_COMMERCE,
          ModulesEnum.ORCAMENTO_WEB,
          ModulesEnum.ORCAMENTO_APP
        ])
      ) {
        setTimeout(() => {
          this.edit.carrossel.ativo = false
        }, 200)

        return this.$vueOnToast.pop(
          'warning',
          'Vincule essa vitrine de produtos à uma categoria'
        )
      } else {
        setTimeout(() => {
          this.edit.carrossel.ativo = !!this.edit.carrossel.ativo
        }, 200)
      }

      if (this.toggleTimeout) {
        clearTimeout(this.toggleTimeout)
      }

      this.toggleTimeout = setTimeout(() => this.save(), 1000)
    },
    prepareDataToSave() {
      if (this.edit.categoriaSelecionada) {
        this.edit.carrossel.idsetor = this.edit.categoriaSelecionada
          ? this.edit.categoriaSelecionada
          : ''
      }

      if (this.edit.tipoCarrosselSelecionado) {
        this.edit.carrossel.config.carouselType = this.edit.tipoCarrosselSelecionado
      }

      if (this.edit.segmentoClienteSelecionado) {
        const segmentoClienteSelecionado = this.edit.segmentoClienteSelecionado
        this.edit.carrossel.idsegmentocliente = Object.keys(
          segmentoClienteSelecionado
        ).includes('value')
          ? segmentoClienteSelecionado.value
          : this.edit.segmentoClienteSelecionado
      } else {
        this.edit.carrossel.idsegmentocliente = null
      }

      this.edit.carrossel.tipo_conteudo = this.edit.tipoConteudo

      if (this.edit.slidesToShowResponsive.mobile === '0') {
        this.edit.carrossel.device = (this.edit.carrossel.device || []).filter(
          d => d !== 'mobile'
        )
      }

      if (this.edit.slidesToShowResponsive.desktop === '0') {
        this.edit.carrossel.device = (this.edit.carrossel.device || []).filter(
          d => d !== 'desktop'
        )
      }
    },
    validate(allowEmpty) {
      if (
        !this.edit.carrossel.slides.length &&
        allowEmpty !== true &&
        this.imageEditorMode
      ) {
        this.edit.carrossel.ativo = false
        return 'Adicione ao menos uma imagem ao carrossel'
      }

      const cantSave =
        !this.edit.carrossel.idsetor &&
        allowEmpty !== true &&
        this.productEditorMode

      if (
        cantSave &&
        this.isCarrosselProdutos &&
        !this.isCarrosselProdutosCliente &&
        this.showComponente([
          ModulesEnum.COMMERCE_APP,
          ModulesEnum.WEB_COMMERCE,
          ModulesEnum.ORCAMENTO_WEB,
          ModulesEnum.ORCAMENTO_APP
        ])
      ) {
        this.edit.carrossel.ativo = false
        return 'Vincule essa vitrine de produtos à uma categoria'
      }

      return
    },
    dataToSave() {
      const localexibicao = this.edit.carrossel.localexibicao.map(local => {
        if (Object.keys(local).includes('value')) {
          const exibicao = local.value.indexOf('|')
          return exibicao > 0 ? local.value.substring(0, exibicao) : local.value
        }

        return local
      })

      this.edit.carrossel.config.slidesToShowResponsive = {
        desktop: this.edit.slidesToShowResponsive.desktop,
        mobile: this.edit.slidesToShowResponsive.mobile,
        fidelidade: this.edit.slidesToShowResponsive.fidelidade
      }

      return {
        ...this.edit.carrossel,
        localexibicao: localexibicao
      }
    },
    save(allowEmpty) {
      this.prepareDataToSave()

      const validation = this.validate(allowEmpty)

      if (validation) return this.$vueOnToast.pop('warning', validation)

      this.$store
        .dispatch(CARROSSEL_UPDATE, {
          idcarrossel: this.slider.idcarrossel,
          configs: this.dataToSave()
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Configurações salvas')
          this.disableToggle = this.getDisableToogle()
        })
    },
    dataChild(e) {
      const { type, value } = e

      if (type === 'edit.actionType') {
        this.edit.actionType = value
      } else {
        this[type] = value
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    refreshSlides(event) {
      this.$emit('refresh', event.response)
    },
    deleteSlide(id) {
      var slideId = id
      var newSlides = Array.from(this.edit.carrossel.slides).filter(function(
        slide
      ) {
        return slide.id !== slideId
      })

      this.$emit('refresh', {
        idcarrossel: this.edit.carrossel.idcarrossel,
        slides: newSlides
      })

      setTimeout(() => this.save(true), 1000)
    },
    refreshSlideAction(updatedSlide) {
      var index = this.slider.slides.findIndex(function(slide) {
        return slide.id == updatedSlide.slideId
      })
      var slidesCopy = Array.from(this.slider.slides)
      if (index >= 0) {
        slidesCopy[index].action = updatedSlide.params
        this.$emit('refresh', {
          idcarrossel: this.edit.carrossel.idcarrossel,
          slides: slidesCopy
        })
      }
    }
  },
  watch: {
    'edit.ordem': {
      deep: true,
      handler: function(newOrder) {
        var ordem = newOrder.split(':')
        this.edit.carrossel.area_pagina = ordem[0]
        this.edit.carrossel.ordem_area = ordem[1]
      }
    },
    'edit.selectedDevice': {
      deep: true,
      handler: function(newSelected) {
        var device = []
        if (newSelected.mobile) {
          device.push('mobile')
        }
        if (newSelected.desktop) {
          device.push('desktop')
        }
        if (newSelected.fidelidade) {
          device.push('fidelidade')
        }
        this.edit.carrossel.device = device
      }
    },
    'edit.autoplaySpeed': {
      deep: true,
      handler: function(newSpeed) {
        this.edit.carrossel.config.autoplaySpeed = newSpeed
        this.edit.carrossel.config.autoplay = newSpeed > 0
      }
    },
    'edit.fade': {
      deep: true,
      handler: function(newFade) {
        this.edit.carrossel.config.fade = newFade
      }
    },
    'slider.slides'(val) {
      this.edit.carrossel.slides = val
    },
    'edit.showTitle': {
      deep: true,
      handler: function(newValue) {
        this.edit.carrossel.config.showTitle = newValue
      }
    },
    'edit.centerMode': {
      deep: true,
      handler: function() {
        this.applyCenterMode()
        this.applyAutoplaySpeed()
        this.applyAutoplay()
        this.applyFade()
      }
    },
    'edit.loginRequired': {
      deep: true,
      handler: function(newValue) {
        this.edit.carrossel.config.loginRequired = newValue
      }
    }
  }
}
</script>

<style scoped>
input {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
}

input:focus {
  outline: 0;
}

.icon {
  order: 0;
  color: #6a6c6f;
}

.title-header {
  order: 1;
  width: 55%;
  color: #6a6c6f;
  padding-left: 24px;
}

.crud-header {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #6a6c6f;
  width: 45%;
}

.description {
  overflow-wrap: break-word;
  word-break: break-all;
  width: 55%;
  text-align: end;
}

.switchCarrossel {
  width: 35%;
  display: flex;
  justify-content: center;
  transform: scale(1.1);
}

.container-icon {
  width: 10%;
}

.iconTrash {
  font-size: 25px;
  background-color: #d0d1d2;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconTrash:hover {
  color: rgb(211, 82, 82);
  transition: all 0.3s linear;
}

.panel-footer {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: -16px;
  margin-left: -24px;
  margin-right: -24px;
  background: #f5f5f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.panel-footer .botao {
  margin-right: 20px;
}
</style>
