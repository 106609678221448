<template>
  <div>
    <CarrosselModalTemplate
      :edit="edit"
      :setoresFolha="setoresFolha"
      :paginasEstaticas="paginasEstaticas"
      :paginasNavegacao="paginasNavegacao"
      :slide="slide"
      :refreshSlideAction="refreshSlideAction"
      @data-reload="dadosComponenteFilho"
      ref="modal"
    />

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Imagem
        </v-card-title>

        <v-card-text>
          <img
            :src="slide.image"
            alt="Imagem carrossel"
            class="imagem-container"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="card-imagem">
      <v-img
        :src="slide.image"
        height="125"
        class="grey darken-4 imagem"
        cover
        @click="dialog = true"
      ></v-img>

      <v-card-title class="text-h6">
        <FormAutocomplete v-model="edit.actionType" :options="select" />

        <FormField label="Links" v-if="hasTextInput" v-model="edit.param" />

        <FormField label="Links" v-if="hasTextInput" v-model="edit.param" />
        <FormSwitch
          label="Combo específico"
          v-if="hasSelectedCombination"
          v-model="edit.combo.comboEspecifico"
        />

        <a
          class="selecao-acao-imagem"
          v-show="hasSelectTarget"
          @click="$refs.modal.openModal()"
        >
          {{ labelCategoria }}
        </a>

        <a
          class="selecao-acao-imagem"
          v-show="hasSelectedProduct"
          @click="$refs.modal.openModal()"
        >
          {{ labelProduct }}
        </a>

        <a
          class="selecao-acao-imagem"
          v-show="hasSelectedCombination && edit.combo.comboEspecifico"
          @click="$refs.modal.openModal()"
        >
          {{ labelCombination }}
        </a>

        <small
          class="pagina-combo-texto"
          v-show="hasSelectedCombination && !edit.combo.comboEspecifico"
        >
          {{ combinationPageText }}
        </small>

        <a
          class="selecao-acao-imagem"
          v-show="hasSelectedStaticPages"
          @click="$refs.modal.openModal()"
        >
          {{ labelStaticPage }}
        </a>

        <a
          class="selecao-acao-imagem"
          v-show="hasSelectedNavigationPages"
          @click="$refs.modal.openModal()"
        >
          {{ labelNavigationPage }}
        </a>

        <div class="botao-exclusao">
          <i class="fas fa-trash iconTrash" @click="deleteSlide(slide.id)"></i>
        </div>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import CarrosselModalTemplate from './CarrosselModalTemplate.vue'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  components: {
    FormAutocomplete,
    FormField,
    CarrosselModalTemplate,
    FormSwitch
  },
  props: {
    showComponente: Function,
    slide: Object,
    paginasNavegacao: Array,
    paginasEstaticas: Array,
    setoresFolha: Array,
    deleteSlide: Function,
    refreshSlideAction: Function
  },
  data() {
    return {
      enumModules: ModulesEnum,
      typeAcao: '',
      actionNavigationTarget: null,
      edit: {
        actionType: 'nothing',
        param: null,
        image: '',
        produto: {},
        combo: {
          comboEspecifico: false
        },
        paginaEstatica: {},
        paginaNavegacao: {}
      },
      dialog: false
    }
  },
  computed: {
    select() {
      const menu = [
        { text: 'Sem ação', value: 'nothing' },
        { text: 'Ir para categoria', value: 'filter:categoria' },
        { text: 'Ir para produto', value: 'product' },
        { text: 'Ir para página estática', value: 'static-page' },
        { text: 'Ir para combo', value: 'combination' },
        { text: 'Ir para página', value: 'page-selection' },
        { text: 'Link externo', value: 'link:external' },
        { text: 'Link incorporado', value: 'link:embedded' }
      ]

      return menu.filter(item => {
        if (
          item.value === 'filter:categoria' ||
          item.value === 'product' ||
          item.value === 'combination'
        ) {
          return this.showComponente([
            this.enumModules.COMMERCE_APP,
            this.enumModules.WEB_COMMERCE,
            this.enumModules.ORCAMENTO_WEB,
            this.enumModules.ORCAMENTO_APP,
            this.enumModules.PORTFOLIO_WEB,
            this.enumModules.PAINEL_CLIENTE
          ])
        }
        return true
      })
    },
    combinationPageText() {
      return 'Será direcionado para a listagem de combos'
    },
    labelCategoria() {
      return (
        (this.actionNavigationTarget && this.actionNavigationTarget.nome) ||
        'Selecionar categoria'
      )
    },
    labelCombination() {
      return (this.edit.combo && this.edit.combo.nome) || 'Selecionar combo'
    },
    labelProduct() {
      return (
        (this.edit.produto &&
          (this.edit.produto.slug || this.edit.produto.descricao)) ||
        'Selecionar produto'
      )
    },
    labelStaticPage() {
      return (
        (this.edit.paginaEstatica &&
          (this.edit.paginaEstatica.titulo ||
            this.edit.paginaEstatica.descricao)) ||
        'Selecionar página estática'
      )
    },
    labelNavigationPage() {
      return (
        (this.edit.paginaNavegacao && this.edit.paginaNavegacao.descricao) ||
        'Selecionar página para navegação'
      )
    },
    hasTextInput: function() {
      return ['link'].indexOf(this.edit.actionType.split(':')[0]) >= 0
    },
    hasSelectTarget: function() {
      return ['filter'].indexOf(this.edit.actionType.split(':')[0]) >= 0
    },
    hasSelectedProduct() {
      return this.edit.actionType === 'product'
    },
    hasSelectedCombination() {
      return this.edit.actionType === 'combination'
    },
    hasSelectedStaticPages() {
      return this.edit.actionType === 'static-page'
    },
    hasSelectedNavigationPages() {
      return this.edit.actionType === 'page-selection'
    }
  },
  methods: {
    dadosComponenteFilho(dados) {
      const { type, value } = dados

      if (type === 'edit.paginaEstatica') {
        this.edit.paginaEstatica = value
      } else {
        this[type] = value
      }
    }
  },
  watch: {
    edit(val) {
      this.edit = { ...this.edit, ...val }
    },
    'edit.actionType': {
      handler(value) {
        this.$emit('data-reload', {
          type: 'edit.actionType',
          value: value
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.card-imagem {
  width: 220px;
}

.imagem {
  height: 150px !important;
}

.botao-exclusao {
  width: 100%;
  color: #6a6c6f;
}

.botao-exclusao i {
  cursor: pointer;
}

.botao-exclusao i:hover {
  color: rgb(239, 80, 80);
  transition: 0.3s all ease-in;
}

.pagina-combo-texto {
  width: 100%;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
}

.selecao-acao-imagem {
  width: 100%;
  font-size: 15px;
  margin-bottom: 20px;
  color: #5870ef;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selecao-acao-imagem:hover {
  color: #3b4ca3;
  transition: 0.3s all ease-in;
}

.container-imagem {
  margin-bottom: 20px;
}

.imagem-container {
  margin-top: 30px;
  max-width: 400px;
  max-height: 400px;
}
</style>
