<template>
  <v-row justify="center">
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon class="icon">fas fa-cog</v-icon>
          </template>

          <div class="title">
            <h3>Configurações avançadas</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="container-config-avancada">
            <div class="container-estilizacao">
              <v-row>
                <v-col cols="12" md="4" class="alinhamento">
                  <div class="devices">
                    <label>Aplicar em</label>

                    <div class="apply-devices">
                      <div class="box-device">
                        <FormCheckbox
                          class="checkbox"
                          label="Mobile"
                          v-model="edit.selectedDevice.mobile"
                        />
                      </div>

                      <input
                        type="number"
                        step="1"
                        min="1"
                        v-model="edit.slidesToShowResponsive.mobile"
                        v-if="edit.selectedDevice.mobile && imageEditorMode"
                      />
                    </div>

                    <div class="apply-devices">
                      <div class="box-device">
                        <FormCheckbox
                          class="checkbox"
                          label="Desktop"
                          v-model="edit.selectedDevice.desktop"
                        />
                      </div>

                      <input
                        type="number"
                        step="1"
                        min="1"
                        v-model="edit.slidesToShowResponsive.desktop"
                        v-if="edit.selectedDevice.desktop && imageEditorMode"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="4" class="alinhamento">
                  <div class="container-estilizacao-seta">
                    <div class="titulo">
                      <label>Estilo de navegação</label>
                      <small>Somente aplicável no e-commerce</small>
                    </div>

                    <div class="setas">
                      <span
                        v-for="(navStyle, index) in navStyles"
                        :key="index"
                        :class="{
                          'seta-selecionada':
                            edit.selectedNavStyle.style == navStyle.style
                        }"
                        @click="selectNavStyle(navStyle)"
                      >
                        <i :class="navStyle.style"></i>
                      </span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="4" class="alinhamento">
                  <div class="container-color-picker">
                    <div class="titulo">
                      <label>Cor da fonte</label>
                    </div>

                    <div class="color-picker">
                      <div class="color">
                        <input
                          type="text"
                          v-model="edit.carrossel.config.colors.font"
                        />

                        <span
                          class="span-clicavel"
                          @click="showColorPicker = !showColorPicker"
                        >
                          <span
                            v-show="!showColorPicker"
                            :style="{ 'background-color': corCarrossel }"
                          >
                          </span>

                          <i
                            class="fas fa-times"
                            :style="{ color: corCarrossel }"
                            v-show="showColorPicker"
                          />
                        </span>
                      </div>

                      <div
                        class="picker"
                        v-if="showColorPicker"
                        ref="picker"
                        @click.stop
                      >
                        <v-color-picker
                          v-model="corCarrossel"
                          hide-inputs
                          show-swatches
                        ></v-color-picker>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="12" md="4">
                <label>Ordenação na página</label>

                <FormAutocomplete
                  v-model="edit.ordem"
                  placeholder="Selecione a ordenação"
                  :options="option"
                />
              </v-col>

              <v-col cols="12" md="4">
                <label>Velocidade</label>

                <FormAutocomplete
                  v-model="edit.autoplaySpeed"
                  placeholder="Selecione a velocidade"
                  :options="velocidade"
                />
              </v-col>

              <v-col cols="12" md="4" v-if="imageEditorMode">
                <label>Tipo de transição</label>

                <FormAutocomplete
                  v-model="edit.fade"
                  placeholder="Selecione o tipo da transição"
                  :options="transicao"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" sm="6" xs="6" v-if="imageEditorMode">
                <FormCheckbox
                  class="checkbox"
                  label="Exibir paginacão horizontal"
                  v-model="edit.carrossel.config.showDots"
                />
              </v-col>

              <v-col cols="12" md="4" sm="6" xs="6" v-if="imageEditorMode">
                <FormCheckbox
                  class="checkbox"
                  label="Fixar topo"
                  v-model="edit.carrossel.config.fixedTop"
                />
              </v-col>

              <v-col cols="12" md="4" sm="6" xs="6">
                <FormCheckbox
                  class="checkbox"
                  label="Exibir título"
                  v-model="edit.showTitle"
                />
              </v-col>

              <v-col cols="12" md="4" sm="6" xs="6" v-if="imageEditorMode">
                <FormCheckbox
                  class="checkbox"
                  label="Ativar destaque centralizado"
                  v-model="edit.centerMode"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="6"
                v-if="
                  !isCarrosselProdutosCliente &&
                    showComponente([
                      enumModules.COMMERCE_APP,
                      enumModules.WEB_COMMERCE
                    ])
                "
              >
                <FormCheckbox
                  class="checkbox"
                  label="Exibir somente para usuários logados"
                  v-model="edit.loginRequired"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="6"
                v-if="
                  showComponente([
                    enumModules.COMMERCE_APP,
                    enumModules.WEB_COMMERCE
                  ])
                "
              >
                <FormCheckbox
                  class="checkbox"
                  :label="titulo"
                  v-model="edit.carrossel.config.showOnPesquisaAtivada"
                />
              </v-col>

              <v-col cols="12" md="4" sm="6" xs="6" v-if="imageEditorMode">
                <FormCheckbox
                  class="checkbox"
                  label="Ativa banner principal"
                  v-model="edit.carrossel.config.bannerPrincipal"
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormCheckbox from '@/components/form/FormCheckbox'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  props: {
    isCarrosselProdutosCliente: Boolean,
    imageEditorMode: Boolean,
    productEditorMode: Boolean,
    showComponente: Function,
    edit: Object
  },
  components: {
    FormAutocomplete,
    FormCheckbox
  },
  data() {
    return {
      enumModules: ModulesEnum,
      velocidade: [
        {
          text: 'Sem transição automática',
          value: 0
        },
        {
          text: 'Conteúdo simples (4 seg)',
          value: 4000
        },
        {
          text: 'Conteúdo moderado (5 seg)',
          value: 5000
        },
        {
          text: 'Conteúdo complexo (6 seg)',
          value: 6000
        }
      ],
      transicao: [
        {
          text: 'Deslizar lateralmente',
          value: false
        },
        {
          text: 'Aparecer suavemente (1 por vez)',
          value: true
        }
      ],
      navStyles: [
        { name: 'angleDouble', style: 'fas fa-angle-double-right' },
        { name: 'chevron', style: 'fas fa-chevron-right' },
        { name: 'carret', style: 'fas fa-caret-right' },
        { name: 'chevronCircle', style: 'fas fa-chevron-circle-right' },
        { name: 'arrowCircle', style: 'fas fa-arrow-circle-right' }
      ],
      showColorPicker: false
    }
  },
  computed: {
    option() {
      const items = []

      for (let i = 1; i < 31; i++) {
        items.push({
          text: `${i}`,
          value: `lista-produtos:${i}`
        })
      }

      return items
    },
    titulo() {
      return `Exibir ${
        this.productEditorMode ? 'vitrine' : 'carrossel'
      } com a pesquisa ativada`
    },
    corCarrossel: {
      get() {
        const corCarrossel = this.edit.carrossel.config.colors.font
        return corCarrossel ? corCarrossel : '#000000FF'
      },

      set(novaCor) {
        this.edit.carrossel.config.colors.font = novaCor
      }
    }
  },
  methods: {
    selectNavStyle(navStyle) {
      this.edit.selectedNavStyle = navStyle
      this.edit.carrossel.config.navStyle = navStyle.style
    }
  }
}
</script>

<style scoped>
h3 {
  color: #6a6c6f;
  font-size: 20px;
}

label {
  font-weight: 600;
}

p {
  margin: 0;
}

.icon {
  order: 0;
  color: #6a6c6f;
}

.title {
  order: 1;
  color: #6a6c6f;
  padding-left: 24px;
}

.container-config-avancada {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 26px;
  color: #6a6c6f;
  display: flex;
  flex-direction: column;
}

.devices {
  width: 180px;
}

.apply-devices {
  display: flex;
  justify-content: center;
  height: 23px;
  margin-top: 10px;
}

.apply-devices input {
  width: 30%;
  border: 2px solid #6a6c6f;
  border-radius: 3px;
  padding: 5px;
  padding-top: 8px;
  text-align: center;
}

.box-device {
  width: 60%;
  display: flex;
  align-items: center;
}

.box-device input {
  min-width: 23px;
  min-height: 23px;
  margin-right: 5px;
}

.box-device .checkbox {
  padding: 0;
  height: 100%;
  margin: 0;
}

.container-estilizacao {
  width: 100%;
  margin-bottom: 15px;
}

.container-estilizacao-seta {
  width: 220px;
}

.container-estilizacao-seta .titulo {
  display: flex;
  flex-direction: column;
}

.container-estilizacao-seta .setas {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.container-estilizacao-seta .setas span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 2px solid #6a6c6f;
  border-radius: 3px;
  cursor: pointer;
}

.container-estilizacao-seta .setas span:hover {
  border-color: #3497dc;
  color: #3497dc;
  transition: 0.3s all ease-in-out;
}

.seta-selecionada {
  border-color: #3497dc !important;
  color: #3497dc;
}

.alinhamento {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-color-picker {
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container-color-picker .titulo {
  text-align: center;
}

.color-picker {
  position: absolute;
  bottom: 0;
}

.color-picker .color {
  width: 100%;
  height: 40px;
  display: flex;
  border: 2px solid #6a6c6f;
  border-radius: 3px;
}

.color-picker .color input {
  width: 80%;
  text-align: center;
  cursor: pointer;
}

.color-picker .color .span-clicavel {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #6a6c6f;
  background-color: rgb(232, 232, 232);
  cursor: pointer;
}

.color-picker .color .span-clicavel > span {
  width: 15px;
  height: 15px;
  display: block;
}

.color-picker .color .span-clicavel > i {
  font-size: 18px;
}

.color-picker .color .span-clicavel:hover {
  background-color: rgb(192, 192, 192);
  transition: 0.3s all ease-in-out;
}

.color-picker .picker {
  width: 220px;
  position: absolute;
  z-index: 1;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:focus {
  outline: none;
}
</style>
