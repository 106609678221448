<template>
  <div class="container-carrossel-conteudo">
    <CarrosselPreVisualizacaoTemplate
      :preview.sync="preview"
      :slides="slider.slides"
      :edit="edit"
      v-if="preview && slider.slides.length"
    />

    <v-row style="margin-bottom: 30px;" v-if="imageEditorMode">
      <Draggable
        v-model="slider.slides"
        @start="drag = true"
        :tag="'div'"
        class="d-flex flex-wrap"
        style="width: 100%;"
        draggable=".draggable"
      >
        <v-col
          v-for="(slide, i) in slider.slides"
          :key="slide.id"
          class="imagem-carrossel draggable"
          cols="12"
          md="3"
        >
          <CarrosselImagemTemplate
            :showComponente="showComponente"
            :slide="slide"
            :index="i"
            :paginasNavegacao="paginasNavegacao"
            :paginasEstaticas="paginasEstaticas"
            :setoresFolha="setoresFolha"
            :deleteSlide="deleteSlide"
            :refreshSlideAction="refreshSlideAction"
            @data-reload="e => $emit('data-reload', e)"
          />
        </v-col>

        <v-col class="imagem-carrossel" cols="12" md="3">
          <DragDropUploader
            initial-icon-class="fas fa-cloud-upload-alt"
            :upload-multiple="true"
            :upload-url="uploadUrl"
            @upload-success="refresh"
          />
        </v-col>
      </Draggable>
    </v-row>

    <div class="container-selects">
      <v-row>
        <v-col cols="12" md="4" v-if="productEditorMode">
          <label>Tipo de vitrine</label>

          <FormAutocomplete
            placeholder="Selecione sua vitrine"
            v-model="edit.tipoCarrosselSelecionado"
            :options="tiposCarrosseis"
            chips
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          v-if="
            productEditorMode &&
              !isCarrosselListaCompras &&
              showComponente([
                enumModules.COMMERCE_APP,
                enumModules.WEB_COMMERCE
              ])
          "
        >
          <label>Segmento de clientes</label>

          <FormAutocomplete
            placeholder="Selecione seu segmento"
            v-model="edit.segmentoClienteSelecionado"
            chips
            :options="segmentosCliente"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          v-if="
            productEditorMode &&
              isCarrosselProdutos &&
              !isCarrosselListaCompras &&
              showComponente([
                enumModules.COMMERCE_APP,
                enumModules.WEB_COMMERCE,
                enumModules.ORCAMENTO_WEB,
                enumModules.ORCAMENTO_APP,
                enumModules.PORTFOLIO_WEB,
                enumModules.PAINEL_CLIENTE
              ])
          "
        >
          <label>Categoria de produtos</label>

          <FormAutocomplete
            placeholder="Selecione sua categoria"
            v-model="edit.categoriaSelecionada"
            :options="setoresFolhaComputed"
            chips
          />
        </v-col>
      </v-row>
    </div>

    <div class="container-date">
      <v-row>
        <v-col cols="12" :md="imageEditorColumn">
          <label>Data inicial</label>

          <FormDatePicker
            v-model="formattedInicioVigencia"
            :clearable="false"
          />
        </v-col>

        <v-col cols="12" :md="imageEditorColumn">
          <label>Data final</label>

          <FormDatePicker v-model="formattedFimVigencia" :clearable="false" />
        </v-col>

        <v-col cols="12" :md="imageEditorColumn">
          <label>{{ localCarrossel }}</label>

          <FormAutocomplete
            placeholder="Selecione o local"
            v-model="edit.selectedLocalExibicao"
            chips
            :multiple="true"
            :options="locaisExibicao()"
          />
        </v-col>

        <v-col
          cols="12"
          :md="imageEditorColumn"
          v-if="
            imageEditorMode &&
              (productEditorMode ||
                (!isCarrosselListaCompras &&
                  showComponente([
                    enumModules.COMMERCE_APP,
                    enumModules.WEB_COMMERCE
                  ])))
          "
        >
          <label>Segmento de clientes</label>

          <FormAutocomplete
            placeholder="Selecione seu segmento"
            v-model="edit.segmentoClienteSelecionado"
            chips
            :options="segmentosCliente"
          />
        </v-col>
      </v-row>
    </div>

    <CarrosselConfiguracoesAvancadaTemplate
      :imageEditorMode="imageEditorMode"
      :productEditorMode="productEditorMode"
      :isCarrosselProdutosCliente="isCarrosselProdutosCliente"
      :showComponente="showComponente"
      :edit="edit"
    />
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormDatePicker from '@/components/form/FormDatePicker'
import DragDropUploader from '@/components/Drag-drop-uploader'
import CarrosselConfiguracoesAvancadaTemplate from './CarrosselConfiguracoesAvancadaTemplate'
import CarrosselImagemTemplate from './CarrosselImagemTemplate'
import CarrosselPreVisualizacaoTemplate from './CarrosselPreVisualizacaoTemplate'
import Draggable from 'vuedraggable'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { mapGetters } from 'vuex'
import * as moment from 'moment'

export default {
  props: {
    imageEditorMode: Boolean,
    productEditorMode: Boolean,
    isCarrosselProdutosCliente: Boolean,
    isCarrosselListaCompras: Boolean,
    isCarrosselProdutos: Boolean,
    showComponente: Function,
    slider: Object,
    paginasEstaticas: Array,
    paginasNavegacao: Array,
    setoresFolha: Array,
    labelCategoria: String,
    labelProduct: String,
    labelStaticPage: String,
    labelNavigationPage: String,
    edit: Object,
    locaisExibicao: Function,
    segmentosCliente: Array,
    computeResumoLocais: Function,
    refresh: Function,
    refreshSlideAction: Function,
    deleteSlide: Function
  },
  components: {
    FormAutocomplete,
    FormDatePicker,
    DragDropUploader,
    CarrosselConfiguracoesAvancadaTemplate,
    CarrosselImagemTemplate,
    CarrosselPreVisualizacaoTemplate,
    Draggable
  },
  data() {
    return {
      enumModules: ModulesEnum,
      preview: false
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId']),

    imageEditorColumn() {
      return this.imageEditorMode ? 3 : 4
    },
    formattedInicioVigencia: {
      get() {
        return moment(this.edit.inicio_vigencia).format('YYYY-MM-DD')
      },
      set(data) {
        const formatedDate = moment(data)
          .utcOffset(3)
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        this.edit.carrossel.inicio_vigencia = formatedDate
        this.edit.inicio_vigencia = formatedDate
      }
    },
    formattedFimVigencia: {
      get() {
        return moment(this.edit.fim_vigencia).format('YYYY-MM-DD')
      },
      set(data) {
        const formatedDate = moment(data)
          .utcOffset(3)
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        this.edit.carrossel.fim_vigencia = formatedDate
        this.edit.fim_vigencia = formatedDate
      }
    },
    uploadUrl() {
      let idcarrossel = 'novo'

      if (this.slider && this.slider.idcarrossel) {
        idcarrossel = this.slider.idcarrossel
      }

      return (
        '/api/web/internal/v2/customizacao/carrossel/upload-image/' +
        this.getFornecedorId +
        '/' +
        idcarrossel
      )
    },
    tiposCarrosseis() {
      if (
        this.showComponente([
          this.enumModules.COMMERCE_APP,
          this.enumModules.WEB_COMMERCE
        ])
      ) {
        return [
          {
            text: 'Vitrine de produtos',
            value: 'CARROSSEL_PRODUTOS'
          },
          {
            text: 'Vitrine de produtos mais comprados pelo cliente',
            value: 'CARROSSEL_PRODUTOS_CLIENTE'
          },
          {
            text: 'Vitrine de lista de compras',
            value: 'CARROSSEL_LISTA_COMPRAS'
          },
          {
            text: 'Vitrine de novos produtos',
            value: 'CARROSSEL_PRODUTOS_NOVOS'
          }
        ]
      } else {
        return [
          {
            text: 'Vitrine de produtos',
            value: 'CARROSSEL_PRODUTOS'
          },
          {
            text: 'Vitrine de novos produtos',
            value: 'CARROSSEL_PRODUTOS_NOVOS'
          }
        ]
      }
    },
    setoresFolhaComputed() {
      return (this.setoresFolha || []).map(setores => {
        return { text: setores.rotulo, value: setores.id }
      })
    },
    localCarrossel() {
      return this.productEditorMode ? 'Local da vitrine' : 'Local do carrossel'
    }
  },
  methods: {
    openPreview() {
      this.preview = true
    }
  },
  watch: {
    'edit.selectedLocalExibicao': {
      deep: true,
      handler: function(newSelected) {
        const app = this
        const locais = (newSelected || []).map(id => {
          return this.locaisExibicao().filter(local => local.value === id)[0]
        })
        const pageAllIndexArray = newSelected.filter(function(selectedItem) {
          return selectedItem == 'page:all'
        })

        if (pageAllIndexArray.length > 0 && newSelected.length > 1) {
          setTimeout(function() {
            app.edit.selectedLocalExibicao = pageAllIndexArray
          }, 100)
        }

        this.edit.carrossel.localexibicao = locais
        this.computeResumoLocais()
      }
    }
  }
}
</script>

<style scoped>
label {
  font-weight: 600;
  padding-left: 21.5px;
}

.container-carrossel-conteudo {
  width: 100%;
  margin-top: 26px;
  color: #6a6c6f;
}

.container-selects {
  text-align: start;
}

.container-date {
  text-align: start;
}

.imagem-carrossel {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
