<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div class="row" v-else-if="showModuloForm === true">
    <CarrosselTemplate
      v-for="(slider, i) in sliders"
      :key="slider.idcarrossel"
      :index="i"
      :slider="slider"
      :setores-folha="setoresFolhaSegmento"
      :segmentos-cliente="segmentosCliente"
      :produtos="produtos"
      :paginas-estaticas="paginasEstaticas"
      :paginas-navegacao="paginasNavegacao"
      :image-editor-mode="true"
      @refresh="refresh"
      @delete="onDeleteCarrossel(slider.idcarrossel)"
    />

    <div class="create-new-carrossel">
      <input
        type="text"
        class="form-control input-md"
        style="border: 0px; width: 50%"
        placeholder="Para criar um novo carrossel, insira um nome aqui"
        v-model="newSliderTitle"
        @keyup.enter="createNewSlider"
      />
    </div>
  </div>
</template>

<script>
import CarrosselTemplate from './template/CarrosselTemplate'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { SEGMENTO_LIST, SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import { TODOS_SETORES } from '@/store/actions/setor'
import {
  CARROSSEL_LIST,
  CARROSSEL_CREATE,
  CARROSSEL_DELETE
} from '@/store/actions/carrossel'
import { PAGINA_ESTATICA_LIST } from '@/store/actions/paginaEstatica'
import { OBTER_PRODUTOS } from '@/store/actions/produto'
import { mapGetters } from 'vuex'

export default {
  components: {
    CarrosselTemplate,
    MarketingModules
  },
  data() {
    return {
      modulosForm: [ModulesEnum.TODOS],
      sliders: [],
      setoresFolhaSegmento: [],
      paginasEstaticas: [],
      segmentosCliente: [],
      newSliderTitle: '',
      paginasNavegacao: [
        {
          descricao: 'Seleção de loja',
          value: 'page-supplier-selection'
        }
      ],
      produtos: []
    }
  },
  computed: {
    ...mapGetters(['moduloFornecedorAtivo', 'getFornecedorId']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  async created() {
    this.getSegmentos()
    this.getSegmentoCliente()
    this.pageStaticList()
    this.getListProducts()
  },
  methods: {
    getSegmentos() {
      this.$store.dispatch(SEGMENTO_LIST).then(segmentos => {
        const idSegmentos = segmentos.map(s => s.idsegmento)

        segmentos.forEach(segmento => {
          this.$store
            .dispatch(TODOS_SETORES, segmento.idsegmento)
            .then(folhas => {
              const setoresFolhaSegmento = folhas.filter(
                folha => idSegmentos.indexOf(folha.idsegmento) >= 0
              )
              this.setoresFolhaSegmento = setoresFolhaSegmento || []

              this.carrosselList()
            })
        })
      })
    },
    getSegmentoCliente() {
      this.$store
        .dispatch(SEGMENTO_CLIENTE_LIST, {
          idfornecedor: this.getFornecedorId
        })
        .then(res => {
          this.segmentosCliente = [{ text: 'Nenhum segmento', value: null }]

          const segmentoReformulado = res.map(segmento => {
            return { text: segmento.nome, value: segmento.idsegmentocliente }
          })

          this.segmentosCliente = this.segmentosCliente.concat(
            segmentoReformulado
          )
        })
    },
    pageStaticList() {
      this.$store.dispatch(PAGINA_ESTATICA_LIST).then(paginas => {
        this.paginasEstaticas = paginas
      })
    },
    getListProducts() {
      this.$store.dispatch(OBTER_PRODUTOS).then(res => {
        this.produtos = res
      })
    },
    createNewSlider() {
      this.$store
        .dispatch(CARROSSEL_CREATE, {
          title: this.newSliderTitle,
          tipoConteudo: 'STATIC_IMAGES'
        })
        .then(newCarrossel => {
          this.sliders.push(newCarrossel)
          this.newSliderTitle = ''
        })
    },
    refresh(novoCarrossel) {
      this.sliders.map(function(slider) {
        if (slider.idcarrossel == novoCarrossel.idcarrossel) {
          slider.slides = novoCarrossel.slides
        }
      })
    },
    updateSlider(newSliderState) {
      this.sliders.map(function(slider) {
        if (slider.idcarrossel == newSliderState.idcarrossel) {
          slider.ativo = newSliderState.ativo
        }
      })
    },
    async carrosselList() {
      const sliders = await this.$store.dispatch(
        CARROSSEL_LIST,
        'STATIC_IMAGES'
      )
      this.sliders = sliders || []
    },
    async onDeleteCarrossel(idcarrossel) {
      await this.carrosselList()

      const slideEncontrado = this.sliders.filter(function(slider) {
        if (slider.idcarrossel == idcarrossel) {
          return slider
        }
      })

      if (slideEncontrado[0].ativo) {
        this.$swal.fire({
          title: `O Carrossel está ativo?`,
          text: 'Inative o carrossel para exclusão',
          type: 'warning',
          confirmButtonText: 'Ok',
          reverseButtons: true
        })
      } else {
        this.$swal
          .fire({
            title: `Apagar esse carrossel?`,
            text: 'Esta operação é irreversível. Continuar?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          })
          .then(result => {
            if (result.value) {
              this.$store
                .dispatch(CARROSSEL_DELETE, idcarrossel)
                .then(() => {
                  this.sliders = Array.from(this.sliders).filter(function(
                    slider
                  ) {
                    return slider.idcarrossel !== idcarrossel
                  })

                  this.$vueOnToast.pop(
                    'success',
                    'Carrossel excluído com sucesso.'
                  )
                })
                .catch(() => {
                  this.$vueOnToast.pop('error', 'Erro ao excluir o carrossel.')
                })
            }
          })
      }
    }
  }
}
</script>

<style scoped>
input {
  font-size: 18px;
  color: #6a6c6f;
  font-weight: bold;
  width: 100%;
  height: 30px;
}

input:focus {
  outline: 0;
}

.create-new-carrossel {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
}

.create-new-carrossel input {
  margin: 6px 25px;
}
</style>
