var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-carrossel-conteudo"},[(_vm.preview && _vm.slider.slides.length)?_c('CarrosselPreVisualizacaoTemplate',{attrs:{"preview":_vm.preview,"slides":_vm.slider.slides,"edit":_vm.edit},on:{"update:preview":function($event){_vm.preview=$event}}}):_vm._e(),(_vm.imageEditorMode)?_c('v-row',{staticStyle:{"margin-bottom":"30px"}},[_c('Draggable',{staticClass:"d-flex flex-wrap",staticStyle:{"width":"100%"},attrs:{"tag":'div',"draggable":".draggable"},on:{"start":function($event){_vm.drag = true}},model:{value:(_vm.slider.slides),callback:function ($$v) {_vm.$set(_vm.slider, "slides", $$v)},expression:"slider.slides"}},[_vm._l((_vm.slider.slides),function(slide,i){return _c('v-col',{key:slide.id,staticClass:"imagem-carrossel draggable",attrs:{"cols":"12","md":"3"}},[_c('CarrosselImagemTemplate',{attrs:{"showComponente":_vm.showComponente,"slide":slide,"index":i,"paginasNavegacao":_vm.paginasNavegacao,"paginasEstaticas":_vm.paginasEstaticas,"setoresFolha":_vm.setoresFolha,"deleteSlide":_vm.deleteSlide,"refreshSlideAction":_vm.refreshSlideAction},on:{"data-reload":function (e) { return _vm.$emit('data-reload', e); }}})],1)}),_c('v-col',{staticClass:"imagem-carrossel",attrs:{"cols":"12","md":"3"}},[_c('DragDropUploader',{attrs:{"initial-icon-class":"fas fa-cloud-upload-alt","upload-multiple":true,"upload-url":_vm.uploadUrl},on:{"upload-success":_vm.refresh}})],1)],2)],1):_vm._e(),_c('div',{staticClass:"container-selects"},[_c('v-row',[(_vm.productEditorMode)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Tipo de vitrine")]),_c('FormAutocomplete',{attrs:{"placeholder":"Selecione sua vitrine","options":_vm.tiposCarrosseis,"chips":""},model:{value:(_vm.edit.tipoCarrosselSelecionado),callback:function ($$v) {_vm.$set(_vm.edit, "tipoCarrosselSelecionado", $$v)},expression:"edit.tipoCarrosselSelecionado"}})],1):_vm._e(),(
          _vm.productEditorMode &&
            !_vm.isCarrosselListaCompras &&
            _vm.showComponente([
              _vm.enumModules.COMMERCE_APP,
              _vm.enumModules.WEB_COMMERCE
            ])
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Segmento de clientes")]),_c('FormAutocomplete',{attrs:{"placeholder":"Selecione seu segmento","chips":"","options":_vm.segmentosCliente},model:{value:(_vm.edit.segmentoClienteSelecionado),callback:function ($$v) {_vm.$set(_vm.edit, "segmentoClienteSelecionado", $$v)},expression:"edit.segmentoClienteSelecionado"}})],1):_vm._e(),(
          _vm.productEditorMode &&
            _vm.isCarrosselProdutos &&
            !_vm.isCarrosselListaCompras &&
            _vm.showComponente([
              _vm.enumModules.COMMERCE_APP,
              _vm.enumModules.WEB_COMMERCE,
              _vm.enumModules.ORCAMENTO_WEB,
              _vm.enumModules.ORCAMENTO_APP,
              _vm.enumModules.PORTFOLIO_WEB,
              _vm.enumModules.PAINEL_CLIENTE
            ])
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Categoria de produtos")]),_c('FormAutocomplete',{attrs:{"placeholder":"Selecione sua categoria","options":_vm.setoresFolhaComputed,"chips":""},model:{value:(_vm.edit.categoriaSelecionada),callback:function ($$v) {_vm.$set(_vm.edit, "categoriaSelecionada", $$v)},expression:"edit.categoriaSelecionada"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"container-date"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.imageEditorColumn}},[_c('label',[_vm._v("Data inicial")]),_c('FormDatePicker',{attrs:{"clearable":false},model:{value:(_vm.formattedInicioVigencia),callback:function ($$v) {_vm.formattedInicioVigencia=$$v},expression:"formattedInicioVigencia"}})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.imageEditorColumn}},[_c('label',[_vm._v("Data final")]),_c('FormDatePicker',{attrs:{"clearable":false},model:{value:(_vm.formattedFimVigencia),callback:function ($$v) {_vm.formattedFimVigencia=$$v},expression:"formattedFimVigencia"}})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.imageEditorColumn}},[_c('label',[_vm._v(_vm._s(_vm.localCarrossel))]),_c('FormAutocomplete',{attrs:{"placeholder":"Selecione o local","chips":"","multiple":true,"options":_vm.locaisExibicao()},model:{value:(_vm.edit.selectedLocalExibicao),callback:function ($$v) {_vm.$set(_vm.edit, "selectedLocalExibicao", $$v)},expression:"edit.selectedLocalExibicao"}})],1),(
          _vm.imageEditorMode &&
            (_vm.productEditorMode ||
              (!_vm.isCarrosselListaCompras &&
                _vm.showComponente([
                  _vm.enumModules.COMMERCE_APP,
                  _vm.enumModules.WEB_COMMERCE
                ])))
        )?_c('v-col',{attrs:{"cols":"12","md":_vm.imageEditorColumn}},[_c('label',[_vm._v("Segmento de clientes")]),_c('FormAutocomplete',{attrs:{"placeholder":"Selecione seu segmento","chips":"","options":_vm.segmentosCliente},model:{value:(_vm.edit.segmentoClienteSelecionado),callback:function ($$v) {_vm.$set(_vm.edit, "segmentoClienteSelecionado", $$v)},expression:"edit.segmentoClienteSelecionado"}})],1):_vm._e()],1)],1),_c('CarrosselConfiguracoesAvancadaTemplate',{attrs:{"imageEditorMode":_vm.imageEditorMode,"productEditorMode":_vm.productEditorMode,"isCarrosselProdutosCliente":_vm.isCarrosselProdutosCliente,"showComponente":_vm.showComponente,"edit":_vm.edit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }