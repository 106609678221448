<template>
  <v-dialog
    v-model="internalPreview"
    @input="closeDialog"
    :width="selectedTamanhoTela"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Pré-visualização - "{{ titulo }}"
      </v-card-title>

      <v-card-text>
        <div class="container-carrossel">
          <FormSelect
            label="Selecione o tamanho da tela"
            class="selectedTamanhoTela"
            v-model="selectedTamanhoTela"
            :options="options"
            :clearable="false"
          />

          <VueSlickCarousel v-bind="slickOptions" class="carrossel">
            <template #prevArrow="arrowOption">
              <div class="custom-arrow prev-arrow">
                <i
                  :class="obterPrevArrowClasses(edit.selectedNavStyle)[0]"
                  :style="{ color: edit.carrossel.config.colors.font }"
                />
              </div>
            </template>
            <template #nextArrow="arrowOption">
              <div class="custom-arrow next-arrow">
                <i
                  :class="obterPrevArrowClasses(edit.selectedNavStyle)[1]"
                  :style="{ color: edit.carrossel.config.colors.font }"
                />
              </div>
            </template>
            <div v-for="slide in slides" :key="slide.image">
              <img
                :src="slide.image"
                :style="{ 'max-width': selectedTamanhoTela }"
                class="imagem-container"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import FormSelect from '@/components/form/FormSelect'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel,
    FormSelect
  },
  props: {
    preview: Boolean,
    slides: Array,
    edit: Object
  },
  created() {
    this.initPreviewModal(this.edit)
  },
  data() {
    return {
      internalPreview: this.preview,
      titulo: '',
      selectedTamanhoTela: '1280px',
      slickOptions: null,
      options: [
        { text: 'Sem ação', value: '1280px' },
        { text: '740px', value: '740px' },
        { text: '320px', value: '320px' }
      ]
    }
  },
  methods: {
    closeDialog() {
      this.internalPreview = false
      this.$emit('update:preview', false)
    },
    obterPrevArrowClasses(selectedNavStyle) {
      let prevArrowClass = 'slider-arrow-left '
      let nextArrowClass = 'slider-arrow-right '

      switch (selectedNavStyle.name) {
        case 'angleDouble': {
          prevArrowClass += 'fas fa-angle-double-left'
          nextArrowClass += 'fas fa-angle-double-right'
          break
        }
        case 'chevron': {
          prevArrowClass += 'fas fa-chevron-left'
          nextArrowClass += 'fas fa-chevron-right'
          break
        }
        case 'carret': {
          prevArrowClass += 'fas fa-caret-left'
          nextArrowClass += 'fas fa-caret-right'
          break
        }
        case 'chevronCircle': {
          prevArrowClass += 'fas fa-chevron-circle-left'
          nextArrowClass += 'fas fa-chevron-circle-right'
          break
        }
        case 'arrowCircle': {
          prevArrowClass += 'fas fa-arrow-circle-left'
          nextArrowClass += 'fas fa-arrow-circle-right'
          break
        }
      }
      return [prevArrowClass, nextArrowClass]
    },
    initPreviewModal(edit) {
      this.titulo = edit.carrossel.titulo

      const autoplay = edit.autoplaySpeed > 0
      let slidesToShow = edit.fade
        ? 1
        : Number(edit.slidesToShowResponsive.desktop)
      let centerMode = edit.centerMode
      let initialSlide = Number(edit.carrossel.ordem_area) - 1
      Number(edit.carrossel.ordem_area) > edit.carrossel.slides.length
        ? (initialSlide = edit.carrossel.slides.length - 1)
        : null

      if (this.selectedTamanhoTela == '320px') {
        centerMode = slidesToShow == 1 ? this.slickOptions.centerMode : false
        slidesToShow = Number(edit.slidesToShowResponsive.mobile)
      }

      this.slickOptions = {
        initialSlide: initialSlide,
        mobileFirst: edit.selectedDevice.mobile,
        slidesToScroll: 1,
        slidesToShow: slidesToShow,
        arrows: true,
        dots: true,
        fade: edit.fade,
        centerPadding: '10px',
        centerMode: centerMode,
        autoplay: autoplay,
        autoplaySpeed: edit.autoplaySpeed,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: slidesToShow
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: slidesToShow
            }
          }
        ]
      }
    }
  },
  watch: {
    preview(newVal) {
      this.internalPreview = newVal
    },
    selectedTamanhoTela() {
      this.initPreviewModal(this.edit)
    }
  }
}
</script>

<style scoped>
.imagem-container {
  max-width: 100%;
  max-height: 400px;
}

.custom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
}

.custom-arrow.prev-arrow::before {
  content: '' !important;
}

.custom-arrow.next-arrow::before {
  content: '' !important;
}

.custom-arrow.slick-prev {
  left: 20px;
  z-index: 1;
}

.custom-arrow.slick-next {
  right: 20px !important;
}

.container-carrossel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.carrossel {
  width: 98%;
}

.custom-arrow.slick-next,
.custom-arrow.slick-prev {
  width: 30px !important;
  height: 30px !important;
}

.selectedTamanhoTela {
  width: 98%;
  margin-top: 20px;
}
</style>
