<template>
  <div v-if="showModal">
    <v-dialog width="80%" scrollable v-model="showModal">
      <v-card style="margin-top: 50px !important;">
        <v-card-title
          class="text-h6 grey lighten-2"
          style="color: #6a6c6f; font-weight: bold;"
        >
          {{ modalTitle }}
        </v-card-title>

        <v-card-text>
          <div v-if="hasSelectTarget">
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="3" xl="2">
                <FormField
                  label="Pesquisar"
                  dense
                  clearable
                  v-model="pesquisar"
                />
              </v-col>
            </v-row>

            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="categorias"
              :items-per-page="10"
            >
              <template v-slot:item.id="{ item }">
                <button class="selecionar" @click="selectCategoria(item)">
                  Selecionar
                </button>
              </template>
            </v-data-table>
          </div>

          <div v-else-if="hasSelectedProduct">
            <DataTable
              sortBy="nome"
              ref="datatable"
              search
              :headers="headers"
              :sortDesc="false"
              :endpoint="endpoint"
            >
              <template v-slot:idproduto="{ row, col }">
                <button class="selecionar" @click="selectProduct(row)">
                  Selecionar
                </button>
              </template>
            </DataTable>
          </div>

          <div v-else-if="hasSelectedCombination">
            <DataTable
              sortBy="nome"
              ref="datatable"
              search
              :headers="headers"
              :sortDesc="false"
              :endpoint="endpoint"
            >
              <template v-slot:idcombopromocional="{ row, col }">
                <button class="selecionar" @click="selectCombination(row)">
                  Selecionar
                </button>
              </template>
            </DataTable>
          </div>

          <div v-else-if="hasSelectedStaticPages">
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="3" xl="2">
                <FormField
                  label="Pesquisar"
                  dense
                  clearable
                  v-model="pesquisar"
                />
              </v-col>
            </v-row>

            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="paginas"
              :items-per-page="10"
            >
              <template v-slot:item.id="{ item }">
                <button class="selecionar" @click="selectPaginaEstatica(item)">
                  Selecionar
                </button>
              </template>
            </v-data-table>
          </div>

          <div v-else-if="hasSelectedNavigationPages">
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="3" xl="2">
                <FormField
                  label="Pesquisar"
                  dense
                  clearable
                  v-model="pesquisar"
                />
              </v-col>
            </v-row>

            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="navegacao"
              :items-per-page="10"
            >
              <template v-slot:item.id="{ item }">
                <button class="selecionar" @click="selectPaginaNavegacao(item)">
                  Selecionar
                </button>
              </template>
            </v-data-table>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <FormButton text="Fechar" type="error" @click="closeModal" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { mapGetters } from 'vuex'

export default {
  props: {
    edit: Object,
    setoresFolha: Array,
    paginasEstaticas: Array,
    paginasNavegacao: Array,
    slide: Object,
    refreshSlideAction: Function
  },
  components: {
    DataTable,
    FormField,
    FormButton
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresaId']),
    endpoint() {
      if (this.edit.actionType === 'combination') {
        return `/api/v4/promotional-combos/${this.getFornecedorId}/${this.getEmpresaId}`
      }

      return `/api/v1/produtos/datableformat?idfornecedor=${this.getFornecedorId}&filtroSimples=disponiveis`
    },
    modalTitle() {
      const title = {
        'filter:categoria': () => 'Selecione uma categoria',
        'static-page': () => 'Selecione uma página estática',
        'page-selection': () => 'Selecione uma página para a navegação',
        product: () => 'Selecione um produto',
        combination: () => 'Selecione um combo'
      }

      return (title[this.edit.actionType] ?? (() => 'Sem título'))()
    },
    categorias() {
      const folhas = (this.localSetoresFolha || []).map(function(folha, index) {
        return {
          id: `categoria:${folha.id}|${index}`,
          nome: folha.rotulo
        }
      })

      return (
        folhas.sort((a, b) => {
          const nomeA = a.nome.toLowerCase()
          const nomeB = b.nome.toLowerCase()

          if (nomeA < nomeB) {
            return -1
          }
          if (nomeA > nomeB) {
            return 1
          }
          return 0
        }) || [{ id: '', nome: '' }]
      )
    },
    paginas() {
      return (this.localPaginasEstaticas || []).map(p => {
        return {
          id: p.idconteudoestatico,
          nome: p.titulo,
          slug: p.slug
        }
      })
    },
    navegacao() {
      return (this.localPaginasNavegacao || []).map(p => {
        return {
          nome: p.descricao,
          id: p.value
        }
      })
    },
    hasSelectTarget: function() {
      return ['filter'].indexOf(this.edit.actionType.split(':')[0]) >= 0
    },
    hasSelectedProduct() {
      return this.edit.actionType === 'product'
    },
    hasSelectedCombination() {
      return this.edit.actionType === 'combination'
    },
    hasSelectedStaticPages() {
      return this.edit.actionType === 'static-page'
    },
    hasSelectedNavigationPages() {
      return this.edit.actionType === 'page-selection'
    }
  },
  data() {
    return {
      headers: [],
      showModal: false,
      localSetoresFolha: this.setoresFolha,
      localPaginasEstaticas: this.paginasEstaticas,
      localPaginasNavegacao: this.paginasNavegacao,
      slideParams: {},
      actionNavigationTarget: null,
      pesquisar: ''
    }
  },
  methods: {
    init() {
      var slideAction = this.slide.action
      var action =
        slideAction.type +
        (slideAction.params && slideAction.params[0]
          ? ':' + slideAction.params[0]
          : '')
      this.edit.actionType = action
      this.edit.param =
        slideAction.params && slideAction.params[0]
          ? slideAction.params[1]
          : null
      if (this.edit.actionType == 'filter:categoria') {
        this.actionNavigationTarget = this.locaisNavegacao().find(function(c) {
          return c.id.split('|')[0] === slideAction.params[1]
        })
      }
      if (slideAction.type === 'static-page') {
        this.edit.paginaEstatica = this.paginasEstaticas.find(c => {
          return (
            c.id === slideAction.params[0] || c.slug === slideAction.params[0]
          )
        })
      }
      if (slideAction.type === 'product') {
        this.edit.produto = {
          id: slideAction.params[0],
          slug: slideAction.params[1],
          descricao: slideAction.params[2] || slideAction.params[1]
        }
      }
      if (slideAction.type === 'combination') {
        this.edit.combo = {
          id: slideAction.params[0],
          nome: slideAction.params[1],
          comboEspecifico: slideAction.params[2]
        }
      }
      if (slideAction.type === 'page-selection') {
        this.edit.paginaNavegacao = this.paginasNavegacao.find(c => {
          return c.value === slideAction.params[0]
        })
      }
    },
    openModal() {
      this.showModal = true
      this.localSetoresFolha = []
      this.localSetoresFolha = this.setoresFolha
      this.tipoHeader()
    },
    closeModal() {
      this.showModal = false
      this.localSetoresFolha = []
      this.pesquisar = ''
    },
    tipoHeader() {
      if (this.edit.actionType === 'product') {
        this.headers = [
          { text: 'Nome', value: 'nome' },
          { text: 'Código de barras', value: 'codigobarras' },
          { text: 'Código interno', value: 'id_produto_erp' },
          {
            text: 'Opções',
            value: 'idproduto',
            align: 'center',
            clickable: false
          }
        ]
      } else if (this.edit.actionType === 'combination') {
        this.headers = [
          { text: 'Nome', value: 'nome' },
          {
            text: 'Opções',
            value: 'idcombopromocional',
            align: 'center',
            clickable: false
          }
        ]
      } else {
        this.headers = [
          { text: 'Nome', value: 'nome' },
          { text: 'Opções', value: 'id', align: 'center', clickable: false }
        ]
      }
    },
    computeSlideParams() {
      let action = this.edit.actionType
      const baseAction = action.split(':')
      this.action = baseAction[0]
      if (this.action === 'filter') {
        const id = this.actionNavigationTarget
          ? this.actionNavigationTarget.id.split('|')[0]
          : ''
        this.params = ['categoria', id]
      } else if (this.action === 'nothing') {
        this.edit.param = 'nothing'
        this.params = []
      } else if (this.action === 'static-page') {
        this.edit.actionType = this.action
        const { slug = '' } = this.edit.paginaEstatica
        this.params = [slug]
      } else if (this.action === 'page-selection') {
        this.edit.actionType = this.action
        const { value = '' } = this.edit.paginaNavegacao
        this.params = [value]
      } else if (this.action === 'product') {
        this.edit.actionType = this.action
        const { id = '', slug = '', nome = '' } = this.edit.produto
        this.params = [id, slug, nome]
      } else if (this.action === 'combination') {
        this.edit.actionType = this.action
        const { id = '', nome = '', comboEspecifico = false } = this.edit.combo
        this.params = [id, nome, comboEspecifico]
      } else {
        if (this.edit.param.startsWith('categoria:')) {
          this.edit.param = ''
          this.actionNavigationTarget = null
        }
        this.params = [baseAction[1], this.edit.param]
      }
      this.setActionParams(this.action, this.params)
    },
    setActionParams(type, params) {
      this.slideParams = {
        type,
        params
      }

      this.refreshSlideAction({
        slideId: this.slide.id,
        params: this.slideParams
      })
    },
    selectCategoria(data) {
      data = { ...data, id: data.id?.split('|')[0] }
      this.actionNavigationTarget = data
      this.processCategoria(data)
      this.closeModal()
    },
    processCategoria({ id } = {}) {
      this.action = 'filter'
      this.params = ['categoria', id]
      this.setActionParams(this.action, this.params)
    },
    locaisNavegacao() {
      var folhas = this.categorias
      return [
        { id: 'page:home', descricao: 'Lista inicial de produtos' }
      ].concat(folhas)
    },
    selectProduct(p) {
      const data = {
        descricao: p.nome.replace(/[^a-zA-Z0-9]/g, ''),
        id: `${p.idproduto}+${p.idcadastroextraproduto}+${p.codigobarras}`,
        slug: p.slug
      }

      this.edit.produto = data
      this.closeModal()
    },
    selectCombination(p) {
      const data = {
        nome: p.nome,
        id: p.idcombopromocional
      }

      this.edit.combo = { ...this.edit.combo, ...data }
      this.closeModal()
    },
    selectPaginaEstatica(p) {
      const data = {
        descricao: p.nome,
        id: p.id,
        slug: p.slug
      }

      this.edit.paginaEstatica = data
      this.closeModal()
    },
    selectPaginaNavegacao(p) {
      const data = {
        descricao: p.nome,
        id: p.id
      }

      this.edit.paginaNavegacao = data
      this.closeModal()
    }
  },
  watch: {
    edit: {
      handler: function() {
        setTimeout(() => this.computeSlideParams(), 200)
      },
      deep: true
    },
    actionNavigationTarget: {
      handler: function(value) {
        this.$emit('data-reload', {
          type: 'actionNavigationTarget',
          value: value
        })
      },
      deep: true
    },
    'edit.paginaEstatica': {
      handler: function(value) {
        this.$emit('data-reload', {
          type: 'edit.paginaEstatica',
          value: value
        })
      },
      deep: true
    },
    pesquisar(pesquisa) {
      if (pesquisa) {
        const acao = {
          'filter:categoria': () => {
            this.localSetoresFolha = []
            this.localSetoresFolha = this.setoresFolha.filter(item =>
              item.rotulo?.toLowerCase().includes(pesquisa?.toLowerCase())
            ) || [{ id: '', nome: '' }]
          },
          'static-page': () => {
            this.localPaginasEstaticas = []
            this.localPaginasEstaticas =
              this.paginasEstaticas.filter(item =>
                item.titulo?.toLowerCase().includes(pesquisa?.toLowerCase())
              ) || this.paginasEstaticas
          },
          'page-selection': () => {
            this.localPaginasNavegacao = []
            this.localPaginasNavegacao =
              this.paginasNavegacao.filter(item =>
                item.descricao?.toLowerCase().includes(pesquisa?.toLowerCase())
              ) || this.paginasNavegacao
          }
        }

        acao[this.edit.actionType]()
      } else {
        const acao = {
          'filter:categoria': () =>
            (this.localSetoresFolha = this.setoresFolha),
          'static-page': () =>
            (this.localPaginasEstaticas = this.paginasEstaticas),
          'page-selection': () =>
            (this.localPaginasNavegacao = this.paginasNavegacao)
        }

        acao[this.edit.actionType]()
      }
    }
  }
}
</script>

<style scoped>
.selecionar {
  background: #e0e0e0;
  width: 100px;
  height: 30px;
  border-radius: 3px;
  color: rgb(106, 108, 111);
  font-weight: bold;
}
</style>
