export const carrossel_types = {
  CARROSSEL_PRODUTOS: 'CARROSSEL_PRODUTOS',
  CARROSSEL_PRODUTOS_CLIENTE: 'CARROSSEL_PRODUTOS_CLIENTE',
  CARROSSEL_PRODUTOS_NOVOS: 'CARROSSEL_PRODUTOS_NOVOS',
  CARROSSEL_LISTA_COMPRAS: 'CARROSSEL_LISTA_COMPRAS'
}

export const carrossel_types_list = {
  type1: [
    {
      text: 'Vitrine de produtos',
      value: 'CARROSSEL_PRODUTOS'
    },
    {
      text: 'Vitrine de produtos mais comprados pelo cliente',
      value: 'CARROSSEL_PRODUTOS_CLIENTE'
    },
    {
      text: 'Vitrine de lista de compras',
      value: 'CARROSSEL_LISTA_COMPRAS'
    },
    {
      text: 'Vitrine de novos produtos',
      value: 'CARROSSEL_PRODUTOS_NOVOS'
    }
  ],
  type2: [
    {
      text: 'Vitrine de produtos',
      value: 'CARROSSEL_PRODUTOS'
    },
    {
      text: 'Vitrine de novos produtos',
      value: 'CARROSSEL_PRODUTOS_NOVOS'
    }
  ]
}
